'use strict';

angular.module('kljDigitalLibraryApp')
    .service('OpenedTabDetailService', ["common", "appConfig", "$q", function(common, appConfig, $q) {

        this.openedTabs = [];
        this.openedTabsToColor = [];
        this.openedNoteTabsToColor = [];
        this.isIdAlreadyPresent = function(id) {
            for (var index in this.openedTabs) {

                if (this.openedTabs[index]._id === id) {
                    return true;
                }
            }
            return false;
        }

        //set party data from api
        this.setPartyData = function(dataSet) {

            for (var index in dataSet) {                
                if (!this.isIdAlreadyPresent(dataSet[index]._id)) {
                    this.openedTabs.push(dataSet[index]);
                }
            }
        }

        //Add single party
        this.addPartyData = function(party) {

            if (!this.isIdAlreadyPresent(party._id)) {
                this.openedTabs.push(party);
            }
            return this.openedTabs;
        }


        //Get Party names of the case
        this.getOpenedTabInformation = function(body) {
            var that = this;
            var url = '/api/cases/getPartyDetails';
            return common.callApi('POST',
                    url,
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE,
                    body
                )
                .then(function(response) {
                    that.setPartyData(response.data);
                    // console.log(that.openedTabs);
                    return that.openedTabs;
                })
        }

        //Remove all the tab from service
        this.removeAllOpenesTabInformation = function() {
            this.openedTabs = [];
            return this.openedTabs;
        }


        //Remove the tab from service
        this.remove = function(tab_id) {

            var newOpenedTabs = [];
            for (var index in this.openedTabs) {
                if (tab_id != this.openedTabs[index]._id) {
                    newOpenedTabs.push(this.openedTabs[index]);
                }
            }
            this.openedTabs = newOpenedTabs;
            return this.openedTabs;
        }

        //Get All opened tabs
        this.getAll = function() {
            return this.openedTabs;
        }

        //Add opened tabs to color
        this.appendOpenedTabsToColor = function(savedTabs) {
            var tabs = savedTabs.split(',');
            for (var index in tabs) {
                if (tabs[index] && this.openedTabsToColor.indexOf(tabs[index])) {
                    this.openedTabsToColor.push(tabs[index]);
                }
            }
            return this.openedTabsToColor;
        }

        //Remove the tabs
        this.removeOpenedTabsToColor = function(id) {
            var tabs = [];

            for (var index in this.openedTabsToColor) {

                if (openedTabsToColor[index] !== id) {
                    tabs.push(this.openedTabsToColor[index]);
                }
            }

            this.openedTabsToColor = tabs;
            return this.openedTabsToColor;
        }

        //Get all tanbs that should be colored
        this.getAllColoredTabs = function() {
            return this.openedTabsToColor;
        }

        //remove all the colored tabs
        this.removeAllColoredTabs = function() {
            this.openedTabsToColor = [];
            return this.openedTabsToColor;
        }

        //Add opened Note tabs to color
        this.appendOpenedNoteTabsToColor = function(savedTabs) {
            var tabs = savedTabs.split(',');
            for (var index in tabs) {
                if (tabs[index] && this.openedNoteTabsToColor.indexOf(tabs[index])) {
                    this.openedNoteTabsToColor.push(tabs[index]);
                }
            }
            return this.openedNoteTabsToColor;
        }

        //Remove the Note tabs
        this.removeNoteOpenedTabsToColor = function(id) {
            var tabs = [];

            for (var index in this.openedNoteTabsToColor) {

                if (openedNoteTabsToColor[index] !== id) {
                    tabs.push(this.openedNoteTabsToColor[index]);
                }
            }

            this.openedNoteTabsToColor = tabs;
            return this.openedNoteTabsToColor;
        }

        //Get all Note tabs that should be colored
        this.getAllNoteColoredTabs = function() {
            return this.openedNoteTabsToColor;
        }

        //remove all the Note colored tabs
        this.removeAllCNoteoloredTabs = function() {
            this.openedNoteTabsToColor = [];
            return this.openedNoteTabsToColor;
        }
    }]);